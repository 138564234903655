body {
  touch-action:none;
}
#canvas1 {
  position: fixed;
  width: 3000px;
  height: 2000px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.App {
  position:fixed;
  height:100%; width:100%;
  overflow:hidden;
}
.App:before {
  content:"";
  position:fixed;
  width:100%;
  height:100%;
  background-color:#33d6ff;
  background:-webkit-gradient(130deg, #7847ff, #4164ff, #33d6ff);
  background:-moz-linear-gradient(130deg, #7847ff, #4164ff, #33d6ff);
  background:-webkit-linear-gradient(310deg, #7847ff, #4164ff, #33d6ff);
  background:-o-linear-gradient(130deg, #7847ff, #4164ff, #33d6ff);
  background:linear-gradient(130deg, #7847ff, #4164ff, #33d6ff);
  -webkit-animation: gra 8s ease infinite;
  -moz-animation: gra 8s ease infinite;
  animation: gra 8s ease infinite;
}
.App::-webkit-scrollbar {
  width:0;
}
.App::-webkit-scrollbar-track {
  background:rgb(255, 255, 255);
}
.App::-webkit-scrollbar-thumb {
  background:linear-gradient(rgb(57, 129, 212) 0%, rgb(202, 227, 235) 100%);
  border-radius:10px;
}

.homeButtonContainer {
  position:absolute;
  width:250px;
  height:80px;
  top:50%; left:50%;
  margin:260px 0 0 -280px;
  padding-bottom:100px;
  transform:translate(-50%, -50%) scale(1.2);
  transition:1s;
  z-index:1;
  cursor:pointer;
  animation:pulseLeft 2s ease;
}
#projectsBtn {
  left:auto; right:50%;
  transform:translate(50%, -50%) scale(1.2);
  margin:260px -280px 0 0;
  animation:pulseRight 2s ease;
}
.homeButtonCircle {
  position:absolute;
  top:50%; left:50%;
  transform:translate(-50%, -50%);
  margin-left:-80px;
  width:70px; height:70px;
  border-radius:100%;
  background:radial-gradient(rgba(241, 252, 255, 0.322), rgba(12, 186, 255, 0));
  box-shadow:0 0 10px rgba(153, 226, 255, 0.26);
  transition:0.5s;
}
#projectsBtn .homeButtonCircle {
  margin-left:80px;
}
.homeButtonContainer:hover .homeButtonCircle {
  box-shadow:0 0 20px rgba(231, 248, 255, 0.377);
}
.homeButtonArrow {
  position:absolute;
  top:50%; left:50%;
  transform:translate(-50%, -50%) rotateZ(45deg);
  width:20px; height:20px;
  border-bottom:2px solid white;
  border-left:2px solid white;
  margin-left:5px;
  opacity: 0.6;
  filter:drop-shadow(0 0 2px white);
}
#projectsBtn .homeButtonArrow {
  transform:translate(-50%, -50%) rotateZ(-135deg);
  margin-left:-5px;
}
.homeButtonText {
  position:absolute;
  top:50%; left:0;
  transform:translate(0%, -50%);
  margin-left:100px;
  font-size:20px;
  font-family: 'Nunito', sans-serif;
  font-weight:400;
  color:white;
  text-shadow:0 0 4px rgb(179, 179, 179);
  opacity:0.85;
  transition:0.5s;
}
.homeButtonText:after {
  content:"";
  position:absolute;
  display:block;
  width:0; height:1px;
  background:white;
  margin-top:4px;
  margin-left:-3px;
  transition:0.5s;
  box-shadow:0 0 5px white;
}
.homeButtonContainer:hover .homeButtonText:after {
  width:95px;
}
#projectsBtn .homeButtonText {
  left:0; right:0;
  text-align:right;
  margin-right:100px;
  margin-left:auto;
}
#projectsBtn .homeButtonText:after {
  position:absolute;
  right:-2px;
  text-align:right;
  width:0;
}
#projectsBtn:hover .homeButtonText:after {
  width:110px;
}

@-webkit-keyframes gra {
  0%{transform:scale(1)}
  50%{transform:scale(2)}
  100%{transform:scale(1)}
}
@-moz-keyframes gra {
  0%{transform:scale(1)}
  50%{transform:scale(2)}
  100%{transform:scale(1)}
}
@keyframes gra { 
  0%{transform:scale(1)}
  50%{transform:scale(2)}
  100%{transform:scale(1)}
}

@keyframes pulseLeft {
  0% {
    opacity:0;
    transform:translate(-50%, -50%) scale(0.5);
  } 70% {
    opacity:0;
    transform:translate(-50%, -50%) scale(0.5);
  } 90% {
    opacity:1;
    transform:translate(-50%, -50%) scale(1.4);
  } 100% {
    opacity:1;
    transform:translate(-50%, -50%) scale(1.2);
  }
}
@keyframes pulseRight {
  0% {
    opacity:0;
    transform:translate(50%, -50%) scale(0.5);
  } 70% {
    opacity:0;
    transform:translate(50%, -50%) scale(0.5);
  } 90% {
    opacity:1;
    transform:translate(50%, -50%) scale(1.4);
  } 100% {
    opacity:1;
    transform:translate(50%, -50%) scale(1.2);
  }
}

@media only screen and (max-width: 1400px) {
  .homeButtonContainer {
    margin:200px 0 0 -250px;
    transform:translate(-50%, -50%) scale(1);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(1);
    margin:200px -250px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.5);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.5);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(1.1);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(1);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.5);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.5);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(1.1);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(1);
    }
  }
}

@media only screen and (max-width: 1024px) {
  #canvas1 {
    width: 1024px;
    height: 1000px;
  }

  .homeButtonContainer {
    margin:160px 0 0 -180px;
    transform:translate(-50%, -50%) scale(0.9);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(0.9);
    margin:160px -180px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.4);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.4);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(1);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.9);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.4);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.4);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(1);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.9);
    }
  }
}

@media only screen and (max-width: 768px) {
  #canvas1 {
    width: 768px;
  }

  .homeButtonContainer {
    margin:140px 0 0 -150px;
    transform:translate(-50%, -50%) scale(0.8);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(0.8);
    margin:140px -150px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.35);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.35);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.9);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.8);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.35);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.35);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.9);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.8);
    }
  }
}

@media only screen and (max-width: 560px) {
  #canvas1 {
    width: 560px;
  }

  .homeButtonContainer {
    margin:120px 0 0 -110px;
    transform:translate(-50%, -50%) scale(0.7);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(0.7);
    margin:120px -110px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.3);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.3);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.775);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.7);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.3);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.3);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.775);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.7);
    }
  }
}

@media only screen and (max-width: 400px) {
  #canvas1 {
    width: 400px;
  }

  .homeButtonContainer {
    margin:90px 0 0 -70px;
    transform:translate(-50%, -50%) scale(0.6);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(0.6);
    margin:90px -70px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.2);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.2);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.65);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.6);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.2);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.2);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.65);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.6);
    }
  }
}

@media only screen and (max-width: 300px) {
  #canvas1 {
    width: 300px;
  }

  .homeButtonContainer {
    margin:65px 0 0 -60px;
    transform:translate(-50%, -50%) scale(0.5);
  }
  #projectsBtn {
    transform:translate(50%, -50%) scale(0.5);
    margin:65px -60px 0 0;
  }

  @keyframes pulseLeft {
    0% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.15);
    } 70% {
      opacity:0;
      transform:translate(-50%, -50%) scale(0.15);
    } 90% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.55);
    } 100% {
      opacity:1;
      transform:translate(-50%, -50%) scale(0.5);
    }
  }
  @keyframes pulseRight {
    0% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.15);
    } 70% {
      opacity:0;
      transform:translate(50%, -50%) scale(0.15);
    } 90% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.55);
    } 100% {
      opacity:1;
      transform:translate(50%, -50%) scale(0.5);
    }
  }
}