.App { overflow-y:auto !important }

.aboutMain {
   display:grid;
   grid-template-columns: 50% 50%;
   height:100%; width:100%;
}

.aboutButtonContainer {
    position:absolute;
    width:190px;
    height:80px;
    top:50px; right:60px;
    transform:scale(1.2);
    transition:0.3s;
    z-index:1;
    cursor:pointer;
    animation:aboutHomeButton 1s ease;
}
.aboutButtonCircle {
    position:absolute;
    top:50%; right:50%;
    transform:translate(50%, -50%);
    margin-right:-50px;
    width:70px; height:70px;
    border-radius:100%;
    background:radial-gradient(rgba(241, 252, 255, 0.322), rgba(12, 186, 255, 0));
    filter:drop-shadow(0 0 0px rgba(255, 255, 255, 0));
    transition:0.5s;
}
@media (hover: hover) {
    .aboutButtonContainer:hover .aboutButtonCircle {
        filter:drop-shadow(0 0 20px rgb(255, 255, 255));
    }
}
.aboutButtonArrow {
    position:absolute;
    top:50%; left:50%;
    transform:translate(-50%, -50%) rotateZ(-135deg);
    width:20px; height:20px;
    border-bottom:2px solid white;
    border-left:2px solid white;
    margin-left:-5px;
    opacity: 0.6;
    filter:drop-shadow(0 0 2px white);
}
.aboutButtonText {
    position:absolute;
    top:50%; left:0;
    transform:translate(0%, -50%);
    margin-left:30px;
    font-size:20px;
    font-family: 'Nunito', sans-serif;
    font-weight:400;
    color:white;
    text-shadow:0 0 4px rgb(179, 179, 179);
    opacity:0.85;
    transition:0.5s;
}
.aboutButtonText:after {
    content:"";
    position:absolute;
    display:block;
    right:-2px;
    text-align:right;
    width:58px; height:1px;
    background:white;
    margin-top:4px;
    margin-left:-3px;
    transition:0.5s;
    box-shadow:0 0 5px white;
    transform:scaleX(0);
    transform-origin:right;
}
@media (hover: hover) {
    .aboutButtonContainer:hover .aboutButtonText:after {
        transform:scaleX(1);
    }
}

.aboutTextContainer {
    position:relative;
    grid-column: 1 / 2;
    height:100%;
    opacity:1;
    transition:1s;
    padding:0 8% 200px 8%;
    transform:translateX(0%);
    pointer-events: none;
}
.aboutTextContainer.switch {
    opacity:0;
    transform:translateX(-70%);
}
.aboutHeader {
    position:relative;
    font-size:80px;
    font-family: 'Fredoka One', cursive;
    font-weight:400;
    padding:20% 0 70px 0;
    color:white;
    animation:titleFade 1s ease;
}
.aboutBio {
    position:relative;
    padding:0px 0 45px 0;
}
.bioLetter {
    font-size:36px;
    line-height:55px;
    font-family: 'Nunito', sans-serif;
    font-weight:400;
    color:white;
    transform:scale(0);
}
.aboutLogoDiv {
    position:relative;
    display:inline-block;
    height:130px; width:100%;
    white-space: nowrap;
}
.aboutLogoDiv:nth-child(3) {
    width:40%;
}
.aboutLogoDiv:nth-child(4) {
    width:60%;
}
.aboutLogoCircle {
    position:relative;
    top:50%;
    transform:translateY(-50%);
    width:80px; height:80px;
    border-radius:100%;
    background:radial-gradient(rgba(241, 252, 255, 0.267), rgba(12, 186, 255, 0));
    box-shadow:0 0 10px rgba(153, 226, 255, 0.26);
}
#ageLogo { animation:logoRoll 2s ease }
#addressLogo { animation:logoRoll 2.25s ease }
#degreeLogo { animation:logoRoll 2.5s ease }
#collegeLogo { animation:logoRoll 2.75s ease }
.aboutLogo {
    position:absolute;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    width:40px; height:40px;
    filter:contrast(0%) brightness(2) drop-shadow(0 0 3px white);
}
.aboutLogoText {
    position:absolute;
    top:50%; transform:translateY(-50%);
    left:110px;
    font-size:24px;
    font-family: 'Nunito', sans-serif;
    font-weight:300;
    letter-spacing:1px;
    color:white;
    text-shadow:0 0 2px white;
    overflow:hidden;
    white-space: nowrap;
}
#ageText { animation:logoTextFade 2.5s ease }
#addressText { animation:logoTextFade 2.75s ease }
#degreeText { animation:logoTextFade 3.25s ease }
#collegeText { animation:logoTextFade 3.75s ease }

.mountainContainer {
    position:relative;
    grid-column: 2 / 3;
    height:100%;
    left:0%;
    transition:1s;
    transform:translateX(0);
    animation:3s mountainFadeUp ease;
}
.mountainContainer.switch,
.mountainContainer.bubble {
    transform:translateX(-100%);
}

.mountain {
    position:absolute;
    transition:1s;
}
.mountainContainer.switch .mountain, 
.mountainContainer.bubble .mountain {
    cursor:pointer;
    overflow:hidden;
}
@media (hover: hover) {
    .mountainContainer.switch .mountain:hover {
        transform:scaleY(1.1);
    }
}
.mountainSwitch {
    position:absolute;
    margin-top:400px; left:calc(50% - 50px);
    top:0px;
    width:100px; height:100px;
    padding:20px;
    border-radius:100%;
    background:radial-gradient(rgba(241, 252, 255, 0.329), rgba(12, 186, 255, 0));
    animation:switchScaleIn 3.5s ease-out, switchPulse 1.5s ease infinite 4s;
    cursor:pointer;
    transition:1s;
}
.mountainContainer.switch .mountainSwitch {
    margin-top:150px;
    left:30vw;
}
.mountainContainer.bubble .mountainSwitch {
    margin-top:50px;
    left:60px;
}
@media (hover: hover) {
    .mountainSwitch:hover {
        animation:switchScaleIn 3.5s ease-out;
        transform:scale(1.2);
        filter:drop-shadow(0 0 40px rgba(255, 255, 255, 0.664));
    }
}
.hikingLogo {
    filter:contrast(0%) brightness(2) drop-shadow(0 0 3px white);
}
.mountainText {
    position:absolute;
    left:50%;
    text-align:center;
    font-size:42px;
    font-family: 'Nunito', sans-serif;
    font-weight:900;
    color:white;
    text-shadow:0 0 10px white;
    letter-spacing:0;
    transform:translate(-50%, 300px);
    pointer-events:none;
    opacity:0;    
    transition:opacity 1s;
}
.mountainContainer.switch .mountainText {
    opacity:1;
}

.bigKnowledgeHeader, .bigKnowledgeSubheader,
.midKnowledgeHeader, .midKnowledgeSubheader,
.smallKnowledgeHeader, .smallKnowledgeSubheader,
.bigExperienceHeader, .bigExperienceSubheader,
.midExperienceHeader, .midExperienceSubheader,
.backgroundHeader {
    position:absolute;
    font-family: 'Fredoka One', cursive;
    font-weight:400;
    color:white;
}
.bigKnowledgeText, .midKnowledgeText, .smallKnowledgeText,
.bigExperienceText, .midExperienceText {
    position:absolute;
    font-family: 'Nunito', sans-serif;
    color:white;
    white-space:nowrap;
}
.bigKnowledgeText { font-weight:900 }
.midKnowledgeText { font-weight:500 }
.smallKnowledgeText { font-weight:200 }

#bigKnowledge, #bigExperience, #bigBackground,
#midKnowledge, #midExperience, #midBackground,
#smallKnowledge, #smallExperience, #smallBackground {
    pointer-events:none;
}

#bigMountain {
    left:0; top:20%;
    width:100%;
    height:80%;
    clip-path: polygon(50% 0%, 50% 0%, 50% 0%, 100% 100%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}
.mountainContainer.switch #bigMountain {
    width:100vw; top:10%;
    height:90%;
}

#bigMountain.bubbleKnowledge {
    left:calc(100% - 585px); top:355px;
    width:500px; height:500px;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    transform:scale(1.8);
}
#bigKnowledge {
    position:absolute;
    width:100%; height:100%;
}
.bigKnowledgeHeader {
    left:0; top:0;
    transform:translate(75%, 15%);
    font-size:40px;
}
.bigKnowledgeHeader:before {
    content:'Advanced';
    position:absolute;
    top:-2px; left:-2px;
    color:rgb(130, 188, 255);
    z-index:-1;
}
.bigKnowledgeHeader:after {
    content:'Advanced';
    position:absolute;
    top:-4px; left:-4px;
    color:rgb(33, 111, 201);
    z-index:-2;
}
.bigKnowledgeSubheader {
    left:0; top:0;
    transform:translate(245%, 170%);
    font-size:27px;
}
.bigKnowledgeSubheader:before {
    content:'5+ Years';
    position:absolute;
    top:-2px; left:-2px;
    color:rgb(130, 188, 255);
    z-index:-1;
}
.bigKnowledgeSubheader:after {
    content:'5+ Years';
    position:absolute;
    top:-4px; left:-4px;
    color:rgb(33, 111, 201);
    z-index:-2;
}
.bigKnowledgeText:nth-child(3) { /* Javascript */
    font-size:27px;
    transform:translate(210%, 330%);
}
.bigKnowledgeText:nth-child(4) { /* CSS */
    font-size:32px;
    transform:translate(124%, 575%);
}
.bigKnowledgeText:nth-child(5) { /* HTML */
    font-size:33px;
    transform:rotateZ(4deg) translate(260%, 610%);
}
.bigKnowledgeText:nth-child(6) { /* Git/Github */
    font-size:24px;
    transform:rotateZ(10deg) translate(258%, 520%);
}
.bigKnowledgeText:nth-child(7) { /* Front-End */
    font-size:30px;
    transform:rotateZ(-8deg) translate(25%, 424%);
}
.bigKnowledgeText:nth-child(8) { /* Agile */
    font-size:30px;
    transform:rotateZ(-4deg) translate(80%, 812%);
}
.bigKnowledgeText:nth-child(9) { /* React */
    font-size:26px;
    transform:rotateZ(10deg) translate(380%, 1020%);
}

#bigMountain.bubbleExperience {
    left:calc(100% - 250px); top:473px;
    width:500px; height:500px;
    clip-path: polygon(0 50%, 0 50%, 100% 50%, 100% 50%, 80% 100%, 80% 100%, 20% 100%, 20% 100%);
    transform-origin: center 43.5%;
    transform:scale(2.2);
}
#bigMountain.rotate {
    transform:scale(2.2) rotateZ(180deg);
}
#bigExperience {
    width:100%; height:50%;
    transform:translateX(0px) translateY(92%) rotateZ(180deg);
}
.bigExperienceHeader {
    position:absolute;
    transform:translate(40%, -45%);
    font-size:20px;
}
.bigExperienceSubheader {
    position:absolute;
    transform:translate(133.75%, 120%);
    font-size:14px;
    font-weight:200;
}
.bigExperienceText { font-size: 13px; }
.bigExperienceText:nth-child(3) {
    transform:translate(45.5%, 269%);
}
.bigExperienceText:nth-child(4) {
    transform:translate(38%, 405%);
}
.bigExperienceText:nth-child(5) {
    transform:translate(33%, 543%);
}
.bigExperienceText:nth-child(6) {
    transform:translate(37.25%, 687%);
}
.bigExperienceText:nth-child(7) {
    transform:translate(58.25%, 788%);
}
.bigExperienceText:nth-child(8) {
    transform:translate(29.75%, 930%);
}

#bigMountain.bubbleBackground {
    left:calc(100% - 250px); top:385px;
    width:500px; height:500px;
    clip-path: polygon(20% 70%, 20% 70%, 80% 70%, 80% 70%, 100% 0, 100% 0, 0 0, 0 0);
    transform:scale(2);
}
#bigBackground {
    height:100%;
    text-align:center;
}
.leftSlant,
.rightSlant {
  width: 37%;
  height: 100%;
}
.leftSlant {
  -webkit-shape-outside: polygon(0 0, 100% 100%, 0 100%);
  shape-outside: polygon(0 0, 100% 100%, 0 100%);
  float: left;
  -webkit-clip-path: polygon(0 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 100%, 0 100%);
}
.rightSlant {
  -webkit-shape-outside: polygon(100% 0, 100% 100%, 0 100%);
  shape-outside: polygon(100% 0, 100% 100%, 0 100%);
  float: right;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}
.backgroundHeader {
    font-size:28px;
    position:static;
    padding:3.3% 0 2% 0;
    text-shadow:0 0 1px white;
}
.backgroundHeader:before {
    content:"My LIfe In Under One Minute";
    z-index:-1;
    position:absolute;
    transform:scale(0.98);
    color:rgb(5, 104, 170);
}
.backgroundText {
    font-family: 'Nunito', sans-serif;
    font-weight:500;
    font-size:12px;
    color:white;
    padding:0.8% 9%;
}
.backgroundText:nth-child(8) {
    font-weight:900;
    font-size:14px;
    padding-top:2.4%;
    white-space:nowrap;
}
.palm {
    position:absolute;
    transform:translate(-50%, -30%) scale(0.5);
    opacity:0.2;
    z-index:-1;
}


#midMountain {
    left:-5vw; top:40%;
    width:70%; height:60%;
    clip-path: polygon(50% 0%, 50% 0%, 50% 0%, 100% 100%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
    filter:drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
}
.mountainContainer.switch #midMountain {
    width:70vw; top:30%;
    height:70%;
}
#midMountain.bubbleKnowledge {
    left:calc(100% + 165px); top:375px;
    width:500px; height:500px;
    clip-path: polygon(36% 0, 70% 5%, 89% 33%, 84% 64%, 59% 85%, 24% 80%, 4% 55%, 10% 21%);
    transform:scale(1.9);
}
#midKnowledge {
    width:81%; height:81%;
    transform:translate(31px, 12px) rotateZ(8.4deg);
}
.midKnowledgeHeader {
    left:0; top:0;
    transform:translate(56%, 30%);
    font-size:32px;
}
.midKnowledgeHeader:before {
    content:'Intermediate';
    position:absolute;
    top:-2px; left:-2px;
    color:rgb(130, 188, 255);
    z-index:-1;
}
.midKnowledgeSubheader {
    left:0; top:0;
    transform:translate(225%, 190%);
    font-size:22px;
}
.midKnowledgeSubheader:before {
    content:'2-5 Years';
    position:absolute;
    top:-2px; left:-2px;
    color:rgb(130, 188, 255);
    z-index:-1;
}
.midKnowledgeText:nth-child(3) { /* VSCode */
    font-size:23px;
    transform:rotateZ(-14deg) translate(180%, 1180%);
}
.midKnowledgeText:nth-child(4) { /* NodeJS */
    font-size:20px;
    transform:rotateZ(-14deg) translate(70%, 900%);
}
.midKnowledgeText:nth-child(5) { /* NextJS */
    font-size:19px;
    transform:rotateZ(0deg) translate(495%, 940%);
}
.midKnowledgeText:nth-child(6) { /* NoSQL */
    font-size:21px;
    transform:translate(130%, 365%);
}
.midKnowledgeText:nth-child(7) { /* C/C++ */
    font-size:20px;
    transform:rotateZ(-7deg) translate(140%, 655%);
}
.midKnowledgeText:nth-child(8) { /* Back-End */
    font-size:23px;
    transform:rotateZ(-10deg) translate(180%, 475%);
}
.midKnowledgeText:nth-child(9) { /* Rule-Based */
    font-size:24px;
    transform:rotateZ(-5deg) translate(165%, 615%);
}
.midKnowledgeText:nth-child(10) { /* Elementor */
    font-size:20px;
    transform:rotateZ(-9deg) translate(105%, 1095%);
}

#midMountain.bubbleExperience {
    left:calc(100% - 250px); top:405px;
    width:500px; height:500px;
    clip-path: polygon(20% 0, 20% 0, 80% 0, 80% 0, 100% 50%, 100% 50%, 0 50%, 0 50%);
    transform-origin: center 56.8%;
    transform:scale(2.2);
}
#midMountain.rotate {
    transform:scale(2.2) rotateZ(180deg);
}
#midExperience {
    width:100%; height:50%;
    transform:translateX(0px) translateY(3%);
}
.midExperienceHeader {
    position:absolute;
    transform:translate(44%, 2%);
    font-size:22px;
    white-space:nowrap;
}
.midExperienceSubheader {
    position:absolute;
    transform:translate(150.5%, 200%);
    font-size:14px;
    font-weight:200;
}
.midExperienceText { font-size: 13px; }
.midExperienceText:nth-child(3) {
    transform:translate(48.4%, 350.5%);
}
.midExperienceText:nth-child(4) {
    transform:translate(57.5%, 508%);
}
.midExperienceText:nth-child(5) {
    transform:translate(71.8%, 607%);
}
.midExperienceText:nth-child(6) {
    transform:translate(41.9%, 763%);
}
.midExperienceText:nth-child(7) {
    transform:translate(38.8%, 856%);
}
.midExperienceText:nth-child(8) {
    transform:translate(39.3%, 1012%);
}

#midMountain.bubbleBackground {
    left:calc(100% - 800px); top:650px;
    width:500px; height:500px;
    clip-path: polygon(9% 48%, 9% 48%, 35% 78%, 35% 78%, 100% 65%, 100% 65%, 44% 0, 44% 0);
    transform:scale(2.1);
}
#midBackground {
    height:70%; width:70%;
    transform:translate(15%, 15%) scale(1.3);
    object-fit: cover;
}
.portrait {
    position:absolute;
    width:100%; height:100%;
    opacity:0.35;
    object-fit:contain;
    transform:scale(0.95);
}

#smallMountain {
    left:20vw; top:50%;
    width:80%; height:50%;
    clip-path: polygon(50% 0%, 50% 0%, 50% 0%, 100% 100%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
    filter:drop-shadow(0px 0px 0px rgba(255, 255, 255, 0));
}
.mountainContainer.switch #smallMountain {
    width:100vw; height:60%;
    top:40%;
    animation:none;
}
#smallMountain.bubbleKnowledge {
    left:calc(100% - 200px); top:1020px;
    width:500px; height:500px;
    clip-path: polygon(30% 6%, 60% 0, 81% 16%, 88% 41%, 75% 65%, 45% 72%, 23% 57%, 16% 30%);
    transform:scale(2.2);
}
#smallKnowledge {
    width:70%; height:70%;
    transform:translate(90px, 12px) rotateZ(-11.3deg);
}
.smallKnowledgeHeader {
    left:0; top:0;
    transform:translate(80%, 12%);
    font-size:28px;
}
.smallKnowledgeSubheader {
    left:0; top:0;
    transform:translate(220%, 150%);
    font-size:19px;
    white-space:nowrap;
}
.smallKnowledgeText:nth-child(3) { /* Angular */
    font-size:18px;
    transform:rotateZ(10deg) translate(250%, 230%);
}
.smallKnowledgeText:nth-child(4) { /* Java */
    font-size:24px;
    transform:rotateZ(20deg) translate(610%, 55%);
}
.smallKnowledgeText:nth-child(5) { /* Azure */
    font-size:22px;
    transform:rotateZ(16deg) translate(220%, 720%);
}
.smallKnowledgeText:nth-child(6) { /* AWS */
    font-size:18px;
    transform:rotateZ(10deg) translate(660%, 510%);
}
.smallKnowledgeText:nth-child(7) { /* TypeScript */
    font-size:19px;
    transform:rotateZ(8deg) translate(70%, 685%);
}
.smallKnowledgeText:nth-child(8) { /* WordPress */
    font-size:18px;
    transform:rotateZ(3deg) translate(195%, 1065%);
}
.smallKnowledgeText:nth-child(9) { /* eCommerce */
    font-size:18px;
    transform:rotateZ(4deg) translate(135%, 565%);
}
.smallKnowledgeText:nth-child(10) { /* Kubernetes */
    font-size:18px;
    transform:rotateZ(13deg) translate(250%, 685%);
}
.smallKnowledgeText:nth-child(11) { /* Docker */
    font-size:18px;
    transform:rotateZ(-10deg) translate(60%, 460%);
}

#smallMountain.bubbleExperience {
    left:calc(100% - 250px); top:438px;
    width:500px; height:500px;
    clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    transform:scale(0.5);
}
#smallExperience {
    width:100%; height:100%;
    pointer-events:all;
}
.rotateArrow {
    position:absolute;
    width:40%; height:40%;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    filter:contrast(0%) brightness(2) drop-shadow(0 0 5px white);
    pointer-events:none;
}
.mountainRotater {
    position:relative;
    height:100%; width:100%;
    animation:none;
    pointer-events:none;
}
#smallMountain.bubbleExperience .mountainRotater {
    pointer-events:all;
    animation:rotateCenter 10s infinite linear;
}

#smallMountain.bubbleBackground {
    left:calc(100% + 310px); top:560px;
    width:500px; height:500px;
    clip-path: polygon(0 60%, 60% 0, 100% 0, 100% 0, 100% 40%, 40% 100%, 0 100%, 0 100%);
    transform:scale(1.5);
}
#smallBackground {
    pointer-events:none;
}
.piano {
    position:absolute;
    width:100%; height:100%;
    opacity:0.3;
    transform:scale(1.1);
    animation:bubbleFadeIn 2s;
}

.knowledge, .experience, .background {
    display:none;
}
.mountain.bubbleKnowledge .knowledge,
.mountain.bubbleExperience .experience,
.mountain.bubbleBackground #bigBackground,
.mountain.bubbleBackground #midBackground {
    display:block;
    animation:bubbleFadeIn 2s;
}

.mountain.bubbleBackground #smallBackground {
    display:block;
}

.hoverMountain {
    display:block;
    transform-origin:bottom;
    background: rgba(88, 188, 255, 0.397);
    width:100%; height:100%;
}
.mountainContainer.switch .hoverMountain:hover {
    cursor:pointer;
}
#bigMountain.bubbleKnowledge .hoverMountain { background: rgba(110, 255, 236, 0.199); }
#midMountain.bubbleKnowledge .hoverMountain { background: rgba(182, 231, 136, 0.274); }
#smallMountain.bubbleKnowledge .hoverMountain { background: rgba(202, 216, 255, 0.287); }

.bubbleKnowledge,
.bubbleExperience,
.bubbleBackground {
    position:absolute;
}

@keyframes aboutHomeButton {
    from {
        opacity:0;
        right:200px;
    } to {
        opacity:1;
        right:60px;
    }
}

@keyframes titleFade {
    from {
        opacity: 0;
        transform:translateY(-50%);
    } to {
        opacity: 1;
        transform:translateY(0%);
    }
}

@keyframes letterFade {
    0% {
        opacity:0;
        transform:scale(0);
    } 60% {
        opacity:0;
        transform:scale(0);
    } 100% {
        opacity:1;
        transform:scale(1);
    }
}

@keyframes logoRoll {
    0% {
        opacity:0;
        transform:translateX(-60%) translateY(-50%) rotateZ(-270deg);
    } 60% {
        opacity:0;
        transform:translateX(-60%) translateY(-50%) rotateZ(-270deg);
    } 100% {
        opacity:1;
        transform:translateX(0%) translateY(-50%) rotateZ(0deg);
    }
}

@keyframes logoTextFade {
    0% {
        opacity:0;
        transform:translateX(-10%) translateY(-50%);
    } 55% {
        opacity:0;
        transform:translateX(-10%) translateY(-50%);
    } 100% {
        opacity:1;
        transform:translateX(0%) translateY(-50%);
    }
}

@keyframes mountainFadeUp {
    from {
        opacity:0;
        transform:scale(0) translateX(0);
        transform-origin:bottom;
    } to {
        opacity:1;
        transform:scale(1) translateX(0);
        transform-origin:bottom;
    }
}

@keyframes switchScaleIn {
    0% {
        opacity:0;
        transform:scale(0.5);
    } 80% {
        opacity:0;
        transform:scale(0.5);
    } 90% {
        opacity:0.8;
        transform:scale(1.3);
    } 100% {
        opacity:1;
        transform:scale(1);
    }
}

@keyframes switchPulse {
    0% {
        filter:drop-shadow(0 0 0px rgba(255, 255, 255, 0));
    } 20% {
        filter:drop-shadow(0 0 40px rgb(255, 255, 255));
    } 100% {
        filter:drop-shadow(0 0 0px rgba(255, 255, 255, 0));
    }
}

@keyframes rotateCenter {
    from {
        transform:rotateZ(0deg)
    } to {
        transform:rotateZ(-360deg)
    }
}

@keyframes bubbleFadeIn {
    0% {
        filter:opacity(0)
    } 20% {
        filter:opacity(0)
    } 100% {
        filter:opacity(1)
    }
}

@media only screen and (max-width:2200px) {
    .aboutLogoDiv:nth-child(3),
    .aboutLogoDiv:nth-child(4) {
        width:50%;
        display:inline-block;
    }
}

@media only screen and (max-width:1620px) {
    .aboutTextContainer {
        padding:0 8% 100px 8%;
    }
    .aboutHeader {
        font-size:53px;
        padding:20% 0 30px 0;
    }
    .aboutBio {
        padding:0px 0 30px 0;
    }
    .bioLetter {
        font-size:26px;
        line-height:45px;
    }
    .aboutLogoDiv {
        height:100px;
    }
    .aboutLogoDiv:nth-child(3),
    .aboutLogoDiv:nth-child(4) {
        width:50%;
    }
    .aboutLogoCircle {
        width:65px; height:65px;
        border-radius:100%;
    }
    .aboutLogo {
        width:32px; height:32px;
    }
    .aboutLogoText {
        left:100px;
        font-size:20px;
        letter-spacing:1px;
    }

    .mountainText {
        font-size:34px;
        transform:translate(-50%, 210px);
    }
    .mountainContainer.switch .mountainSwitch {
        margin-top:100px;
        margin-left:-100px;
        transform:scale(0.9);
    }

    #bigMountain.bubbleKnowledge {
        left:calc(100% - 530px); top:245px;
        transform:scale(1.3);
    }
    .bigKnowledgeText:nth-child(3) { /* Javascript */
        transform:translate(230%, 330%);
    }
    .bigKnowledgeText:nth-child(4) { /* CSS */
        transform:rotateZ(-4deg) translate(124%, 575%);
    }
    .bigKnowledgeText:nth-child(5) { /* HTML */
        transform:rotateZ(4deg) translate(270%, 600%);
    }
    .bigKnowledgeText:nth-child(6) { /* Git/Github */
        transform:rotateZ(10deg) translate(313%, 490%);
    }
    .bigKnowledgeText:nth-child(7) { /* Front-End */
        transform:rotateZ(-8deg) translate(25%, 384%);
    }
    .bigKnowledgeText:nth-child(8) { /* Agile */
        transform:rotateZ(-2deg) translate(88%, 818%);
    }
    .bigKnowledgeText:nth-child(9) { /* React */
        transform:rotateZ(-4deg) translate(230%, 1160%);
    }
    #bigMountain.bubbleExperience {
        left:calc(100% - 250px);
        transform-origin: center 44%;
        transform:scale(2);
    }
    #bigMountain.bubbleBackground {
        left:calc(100% - 230px); top:335px;
        transform:scale(1.7);
    }

    #midMountain.bubbleKnowledge {
        left:calc(100% + 70px); top:300px;
        transform:scale(1.5);
    }
    .midKnowledgeText:nth-child(3) { /* VSCode */ transform:rotateZ(-14deg) translate(180%, 1180%); }
    .midKnowledgeText:nth-child(4) { /* NodeJS */ 
        transform:rotateZ(-14deg) translate(30%, 740%); 
        font-size:24px; 
    }
    .midKnowledgeText:nth-child(5) { /* NextJS */ transform:rotateZ(0deg) translate(495%, 950%); }
    .midKnowledgeText:nth-child(6) { /* NoSQL */ transform:translate(110%, 355%); }
    .midKnowledgeText:nth-child(7) { /* C/C++ */ transform:rotateZ(-7deg) translate(70%, 635%); }
    .midKnowledgeText:nth-child(8) { /* Back-End */ transform:rotateZ(-10deg) translate(190%, 475%); }
    .midKnowledgeText:nth-child(9) { /* Rule-Based */ transform:rotateZ(-5deg) translate(165%, 615%); }
    .midKnowledgeText:nth-child(10) { /* Elementor */ transform:rotateZ(-9deg) translate(105%, 1095%); }
    #midMountain.bubbleExperience {
        left:calc(100% - 250px);
        transform-origin: center 56%;
        transform:scale(2);
    }
    #midMountain.bubbleBackground {
        left:calc(100% - 680px); top:600px;
        transform:scale(1.7);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(100% - 250px); top:810px;
        transform:scale(1.8);
    }
    #smallMountain.bubbleExperience {
        left:calc(100% - 250px);
        transform:scale(0.4);
    }
    #smallMountain.bubbleBackground {
        left:calc(100% + 160px); top:525px;
        transform:scale(1.2);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(2) rotateZ(180deg);
    }
}

@media only screen and (max-width:1300px) {
    .aboutTextContainer {
        padding:0 8% 80px 8%;
    }
    .aboutLogoDiv:nth-child(3) {
        width:55%;
    }
    .aboutLogoDiv:nth-child(4) {
        width:45%;
    }

    #bigMountain.bubbleExperience {
        transform:scale(1.7);
        transform-origin: center 44.5%;
        top:418px;
    }
    #midMountain.bubbleExperience {
        transform:scale(1.7);
        transform-origin: center 55.5%;
        top:367px;
    }
    #smallMountain.bubbleExperience {
        transform:scale(0.35);
        top:393px;
    }

    #bigMountain.bubbleBackground {
        left:calc(100% - 230px); top:320px;
        transform:scale(1.6);
    }
    #midMountain.bubbleBackground {
        left:calc(100% - 600px); top:555px;
        transform:scale(1.3);
    }
    #smallMountain.bubbleBackground {
        left:calc(100% + 130px); top:495px;
        transform:scale(0.9);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1.7) rotateZ(180deg);
    }
}

@media only screen and (max-width:1160px) {
    .aboutHeader {
        font-size:45px;
        padding:20% 0 20px 0;
    }
    .aboutBio {
        padding:0px 0 30px 0;
    }
    .bioLetter {
        font-size:22px;
        line-height:40px;
    }
    .aboutLogoDiv {
        height:85px;
    }
    .aboutLogoDiv:nth-child(3) {
        width:60%;
    }
    .aboutLogoDiv:nth-child(4) {
        width:40%;
    }
    .aboutLogoCircle {
        width:60px; height:60px;
    }
    .aboutLogo {
        width:29px; height:29px;
    }
    .aboutLogoText {
        left:85px;
        font-size:20px;
        letter-spacing:1px;
    }

    .mountainText {
        font-size:31px;
        transform:translate(-50%, 190px);
    }
    .mountainSwitch {
        margin-top:300px;
        left:calc(50% - 50px);
        z-index:1;
    }

    #bigMountain.bubbleKnowledge {
        left:calc(100% - 475px); top:225px;
        transform:scale(1.1);
    }
    #bigMountain.bubbleExperience {
        left:calc(100% - 250px); top:448px;
        transform:scale(1.8);
    }
    #bigMountain.bubbleBackground {
        left:calc(100% - 230px); top:295px;
        transform:scale(1.5);
    }

    #midMountain.bubbleKnowledge {
        left:calc(100% + 20px); top:280px;
        transform:scale(1.2);
    }
    #midMountain.bubbleExperience {
        left:calc(100% - 250px); top:397px;
        transform:scale(1.8);
    }
    #midMountain.bubbleBackground {
        left:calc(100% - 550px); top:550px;
        transform:scale(1.2);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(100% - 255px); top:690px;
        transform:scale(1.5);
    }
    #smallMountain.bubbleExperience {
        left:calc(100% - 250px); top:423px;
    }
    #smallMountain.bubbleBackground {
        left:calc(100% + 80px); top:490px;
        transform:scale(0.87);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1.8) rotateZ(180deg);
    }
}

@media only screen and (max-width:1040px) {
    .aboutButtonContainer {
        transform:scale(0.95);
        top:20px; right:15px;
    }
    
    .aboutTextContainer {
        padding:0px 9% 200px 9%;
        width:200%;
        z-index:1;
    }
    .aboutHeader {
        font-size:46px;
        padding:18% 0 30px 0;
        width:100%;
    }
    .aboutBio {
        padding:0px 0 35px 0;
    }
    .bioLetter {
        font-size:24px;
        line-height:42px;
    }
    .aboutLogoDiv:nth-child(3),
    .aboutLogoDiv:nth-child(4) {
        width:50%;
    }
    .aboutLogoCircle {
        width:55px; height:55px;
        border-radius:100%;
    }
    .aboutLogo {
        width:27px; height:27px;
    }
    .aboutLogoText {
        left:80px;
        font-size:19px;
        letter-spacing:1px;
    }

    .mountainText {
        font-size:31px;
        transform:translate(-50%, 250px);
    }
    .mountainContainer {
        transform:translateX(-50%);
        width:200%;
    }
    .mountainContainer.switch,
    .mountainContainer.bubble {
        transform:translateX(-50%);
    }
    .hoverMountain {
        background: rgba(88, 188, 255, 0.233);
    }
    .mountainSwitch {
        margin-top:720px;
        left:calc(50% - 50px);
        transform:scale(0.85);
    }
    @media (hover: hover) {
        .mountainSwitch:hover {
            transform:scale(1);
        }
    }
    .mountainContainer.switch .mountainSwitch {
        bottom:calc(100% - 250px);
        margin-left:-80px;
        transform:scale(0.9);
    }
    .mountainContainer.bubble .mountainSwitch {
        top:-30px;
        bottom:0px;
        margin-bottom:0;
        left:20px;
        transform:scale(0.8);
    }

    #bigMountain {
        width:150%;
    }
    #midMountain {
        width:110%;
    }
    #smallMountain {
        left:50vw;
    }
    .mountainContainer.switch #bigMountain {
        left:0vw;
    }
    .mountainContainer.switch #midMountain {
        left:-5vw;
    }
    .mountainContainer.switch #smallMountain {
        left:20vw;
    }
    
    #bigMountain.bubbleKnowledge {
        left:calc(50% - 420px); top:105px;
        transform:scale(0.9);
    }
    .bigKnowledgeText:nth-child(3) { /* Javascript */
        transform:translate(220%, 340%);
    }
    .bigKnowledgeText:nth-child(4) { /* CSS */
        transform:rotateZ(-4deg) translate(130%, 555%);
    }
    .bigKnowledgeText:nth-child(5) { /* HTML */
        transform:rotateZ(4deg) translate(300%, 590%);
    }
    .bigKnowledgeText:nth-child(6) { /* Git/Github */
        transform:rotateZ(10deg) translate(280%, 500%);
    }
    .bigKnowledgeText:nth-child(7) { /* Front-End */
        transform:rotateZ(-8deg) translate(25%, 384%);
    }
    .bigKnowledgeText:nth-child(8) { /* Agile */
        transform:rotateZ(-2deg) translate(95%, 780%);
    }
    .bigKnowledgeText:nth-child(9) { /* React */
        font-size:30px;
        transform:rotateZ(-4deg) translate(240%, 920%);
    }
    #bigMountain.bubbleExperience {
        left:calc(50% - 250px); top:358px;
        transform-origin: center 43%;
        transform:scale(1.6);
    }
    #bigMountain.bubbleBackground {
        left:calc(50% - 250px); top:225px;
        transform:scale(1.3);
    }

    #midMountain.bubbleKnowledge {
        left:calc(50% - 37px); top:160px;
        transform:scale(1.05);
    }
    #midMountain.bubbleExperience {
        left:calc(50% - 250px); top:287px;
        transform-origin: center 57%;
        transform:scale(1.6);
    }
    .midKnowledgeText:nth-child(3) { /* VSCode */ transform:rotateZ(-14deg) translate(150%, 1190%); }
    .midKnowledgeText:nth-child(4) { /* NodeJS */ transform:rotateZ(-14deg) translate(50%, 740%); }
    .midKnowledgeText:nth-child(5) { /* NextJS */ transform:rotateZ(0deg) translate(475%, 960%); }
    .midKnowledgeText:nth-child(6) { /* NoSQL */ transform:translate(140%, 355%); }
    .midKnowledgeText:nth-child(7) { /* C/C++ */ transform:rotateZ(-7deg) translate(120%, 635%); }
    .midKnowledgeText:nth-child(8) { /* Back-End */ transform:rotateZ(-10deg) translate(190%, 475%); }
    .midKnowledgeText:nth-child(9) { /* Rule-Based */ transform:rotateZ(-5deg) translate(165%, 615%); }
    .midKnowledgeText:nth-child(10) { /* Elementor */ transform:rotateZ(-9deg) translate(85%, 1125%); }
    #midMountain.bubbleBackground {
        left:calc(50% - 490px); top:480px;
        transform:scale(1);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(50% - 260px); top:545px;
        transform:scale(1.28);
    }
    #smallMountain.bubbleExperience {
        left:calc(50% - 250px); top:323px;
        transform:scale(0.35);
    }
    #smallMountain.bubbleBackground {
        left:calc(50% - 10px); top:420px;
        transform:scale(0.7);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1.6) rotateZ(180deg);
    }

    @keyframes aboutHomeButton {
        from {
            opacity:0;
            right:200px;
        } to {
            opacity:1;
            right:15px;
        }
    }
    @keyframes switchScaleIn {
        0% {
            opacity:0;
            transform:scale(0.4);
        } 80% {
            opacity:0;
            transform:scale(0.4);
        } 90% {
            opacity:0.8;
            transform:scale(1.1);
        } 100% {
            opacity:1;
            transform:scale(0.85);
        }
    }
    @keyframes mountainFadeUp {
        from {
            opacity:0;
            transform:scale(0) translateX(0);
            transform-origin:bottom;
        } to {
            opacity:1;
            transform:scale(1) translateX(-50%);
            transform-origin:bottom;
        }
    }
}

@media only screen and (max-width:740px) {
    .aboutButtonContainer {
        transform:scale(0.9);
        top:10px; right:5px;
        z-index:2;
    }

    .aboutHeader {
        font-size:42px;
        padding:22% 0 25px 0;
        width:450px;
    }
    .aboutBio {
        padding:0px 0 25px 0;
    }
    .bioLetter {
        font-size:20px;
        line-height:40px;
    }
    .aboutLogoDiv {
        height:75px;
    }
    .aboutLogoDiv:nth-child(3),
    .aboutLogoDiv:nth-child(4) {
        width:50%;
    }
    .aboutLogoCircle {
        width:52px; height:52px;
        border-radius:100%;
    }
    .aboutLogo {
        width:25px; height:25px;
    }
    .aboutLogoText {
        left:75px;
        font-size:17px;
        letter-spacing:1px;
    }

    .mountainText {
        font-size:28px;
        transform:translate(-50%, 220px);
    }

    .mountainContainer {
        transform:translateX(-75%);
    }
    .mountainSwitch {
        margin-top:720px;
        margin-left:24.9%;
        left:none; right:none;
        transform:scale(0.9);
    }
    .mountainContainer.switch .mountainSwitch {
        margin-left:-80px;
        transform:scale(0.75);
    }
    .mountainContainer.bubble .mountainSwitch {
        top:-45px;
        left:-23%;
        transform:scale(0.75);
    }

    #bigMountain {
        width:150%;
    }
    #midMountain {
        width:110%;
    }
    #smallMountain {
        width:140%;
        left:30vw;
    }
    .mountainContainer.switch #bigMountain {
        left:-19vw;
        width:140%;
    }
    .mountainContainer.switch #midMountain {
        left:-31vw;
        width:110%;
    }
    .mountainContainer.switch #smallMountain {
        left:4vw;
        width:150%;
    }

    #bigMountain.bubbleKnowledge {
        left:calc(50% - 300px); top:60px;
        transform:scale(0.8);
    }
    .bigKnowledgeText:nth-child(3) { /* Javascript */
        transform:translate(240%, 340%);
    }
    .bigKnowledgeText:nth-child(4) { /* CSS */
        transform:rotateZ(-4deg) translate(130%, 535%);
    }
    .bigKnowledgeText:nth-child(5) { /* HTML */
        transform:rotateZ(4deg) translate(320%, 600%);
    }
    .bigKnowledgeText:nth-child(6) { /* Git/Github */
        transform:rotateZ(10deg) translate(310%, 490%);
    }
    .bigKnowledgeText:nth-child(7) { /* Front-End */
        transform:rotateZ(-8deg) translate(35%, 370%);
    }
    .bigKnowledgeText:nth-child(8) { /* Agile */
        transform:rotateZ(-2deg) translate(95%, 760%);
    }
    .bigKnowledgeText:nth-child(9) { /* React */
        font-size:30px;
        transform:rotateZ(-4deg) translate(180%, 960%);
    }
    #bigMountain.bubbleExperience {
        left:calc(50% - 250px); top:348px;
        transform:scale(1.5);
    }
    #bigMountain.bubbleBackground {
        left:calc(50% - 250px); top:165px;
        transform:scale(1.15);
    }

    #midMountain.bubbleKnowledge {
        left:calc(50% - 175px); top:400px;
        transform:scale(0.9);
    }
    .midKnowledgeText:nth-child(3) { /* VSCode */ transform:rotateZ(-14deg) translate(180%, 1130%); }
    .midKnowledgeText:nth-child(4) { /* NodeJS */ transform:rotateZ(-14deg) translate(5%, 860%); }
    .midKnowledgeText:nth-child(5) { /* NextJS */ transform:rotateZ(0deg) translate(475%, 660%); }
    .midKnowledgeText:nth-child(6) { /* NoSQL */ transform:translate(130%, 355%); }
    .midKnowledgeText:nth-child(7) { /* C/C++ */ transform:rotateZ(-7deg) translate(230%, 820%); }
    .midKnowledgeText:nth-child(8) { /* Back-End */ transform:rotateZ(-10deg) translate(190%, 475%); }
    .midKnowledgeText:nth-child(9) { /* Rule-Based */ transform:rotateZ(-5deg) translate(165%, 785%); }
    .midKnowledgeText:nth-child(10) { /* Elementor */ 
        transform:rotateZ(-9deg) translate(5%, 530%); 
        font-size:24px;
    }
    #midMountain.bubbleExperience {
        left:calc(50% - 250px); top:277px;
        transform:scale(1.5);
    }
    #midMountain.bubbleBackground {
        left:calc(50% - 400px); top:420px;
        transform:scale(0.8);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(50% - 300px); top:720px;
        transform:scale(1);
    }
    #smallMountain.bubbleExperience {
        left:calc(50% - 250px); top:313px;
        transform:scale(0.32);
    }
    #smallMountain.bubbleBackground {
        left:calc(50% - 110px); top:380px;
        transform:scale(0.6);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1.5) rotateZ(180deg);
    }

    @keyframes aboutHomeButton {
        from {
            opacity:0;
            right:200px;
        } to {
            opacity:1;
            right:5px;
        }
    }
    @keyframes mountainFadeUp {
        from {
            opacity:0;
            transform:scale(0) translateX(0);
            transform-origin:bottom;
        } to {
            opacity:1;
            transform:scale(1) translateX(-75%);
            transform-origin:bottom;
        }
    }
    @keyframes switchScaleIn {
        0% {
            opacity:0;
            transform:scale(0.4);
        } 80% {
            opacity:0;
            transform:scale(0.4);
        } 90% {
            opacity:0.8;
            transform:scale(1.1);
        } 100% {
            opacity:1;
            transform:scale(0.9);
        }
    }
}

@media only screen and (max-width:520px) {
    .aboutButtonContainer {
        transform:scale(0.85);
        top:15px; right:5px;
        z-index:2;
    }

    .aboutHeader {
        font-size:38px;
        padding:32% 0 20px 0;
        width:350px;
    }
    .aboutBio {
        padding:0px 0 25px 0;
    }
    .bioLetter {
        font-size:17px;
        line-height:36px;
    }
    .aboutLogoDiv {
        height:60px;
    }
    .aboutLogoDiv:nth-child(3),
    .aboutLogoDiv:nth-child(4) {
        width:100%;
    }
    .aboutLogoCircle {
        width:45px; height:45px;
    }
    .aboutLogo {
        width:22px; height:22px;
    }
    .aboutLogoText {
        left:65px;
        font-size:16px;
        letter-spacing:1px;
    }

    .mountainSwitch {
        margin-top:730px;
        transform:scale(0.75);
    }
    @media (hover: hover) {
        .mountainSwitch:hover {
            transform:scale(1);
        }
    }
    .mountainContainer.switch .mountainSwitch {
        margin-left:-90px;
        transform: scale(0.7);
    }
    .mountainContainer.bubble .mountainSwitch {
        top:-42px;
        transform:scale(0.7);
    }

    #bigMountain.bubbleKnowledge {
        left:calc(50% - 290px); top:30px;
        transform:scale(0.6);
    }
    #bigMountain.bubbleExperience {
        left:calc(50% - 250px); top:258px;
        transform-origin: center 42.5%;
        transform:scale(1.2);
    }
    #bigMountain.bubbleBackground {
        left:calc(50% - 250px); top:125px;
        transform:scale(1);
    }

    #midMountain.bubbleKnowledge {
        left:calc(50% - 200px); top:300px;
        transform:scale(0.7);
    }
    #midMountain.bubbleExperience {
        left:calc(50% - 250px); top:187px;
        transform-origin: center 57.5%;
        transform:scale(1.2);
    }
    #midMountain.bubbleBackground {
        left:calc(50% - 320px); top:410px;
        transform:scale(0.8);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(50% - 300px); top:560px;
        transform:scale(0.8);
    }
    #smallMountain.bubbleExperience {
        left:calc(50% - 250px); top:223px;
        transform:scale(0.27);
    }
    #smallMountain.bubbleBackground {
        left:calc(50% - 180px); top:510px;
        transform:scale(0.6);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1.2) rotateZ(180deg);
    }

    @keyframes aboutHomeButton {
        from {
            opacity:0;
            right:125px;
        } to {
            opacity:1;
            right:5px;
        }
    }

    @keyframes switchScaleIn {
        0% {
            opacity:0;
            transform:scale(0.3);
        } 80% {
            opacity:0;
            transform:scale(0.3);
        } 90% {
            opacity:0.8;
            transform:scale(1);
        } 100% {
            opacity:1;
            transform:scale(0.75);
        }
    }
}

@media only screen and (max-width:420px) {
    .aboutButtonContainer {
        transform:scale(0.8);
        top:10px; right:0px;
        z-index:2;
    }
    .remove {
        display:none;
    }

    .aboutTextContainer {
        padding:0px 9% 180px 9%;
    }
    .aboutHeader {
        font-size:34px;
        padding:36% 0 15px 0;
        width:350px;
    }
    .bioLetter {
        font-size:16px;
        line-height:32px;
    }
    .aboutLogoDiv {
        height:65px;
    }
    .aboutLogoCircle {
        width:45px; height:45px;
    }
    .aboutLogo {
        width:22px; height:22px;
    }
    .aboutLogoText {
        left:65px;
        font-size:16px;
        letter-spacing:1px;
        width:220px;
        white-space:normal;
    }

    .mountainText {
        font-size:21px;
        transform:translate(-50%, 220px);
    }
    .mountainSwitch {
        margin-top:730px;
        transform:scale(0.65);
    }
    @media (hover: hover) {
        .mountainSwitch:hover {
            transform:scale(0.9);
        }
    }
    .mountainContainer.switch .mountainSwitch {
        margin-left:-85px;
        transform: scale(0.65);
    }
    .mountainContainer.bubble .mountainSwitch {
        top:-48px;
        left:-23.2%;
        transform:scale(0.65);
    }

    #bigMountain.bubbleKnowledge {
        left:calc(50% - 270px); top:0px;
        transform:scale(0.5);
    }
    .bigKnowledgeText:nth-child(4) { /* CSS */
        transform:rotateZ(-4deg) translate(250%, 535%);
    }
    .bigKnowledgeText:nth-child(5) { /* HTML */
        transform:rotateZ(4deg) translate(370%, 610%);
    }
    .bigKnowledgeText:nth-child(8) { /* Agile */
        transform:rotateZ(-2deg) translate(100%, 720%);
    }
    .bigKnowledgeText:nth-child(9) { /* React */
        font-size:30px;
        transform:rotateZ(-4deg) translate(210%, 900%);
    }
    #bigMountain.bubbleExperience {
        left:calc(50% - 250px); top:188px;
        transform-origin: center 43%;
        transform:scale(1);
    }
    #bigMountain.bubbleBackground {
        left:calc(50% - 250px); top:110px;
        clip-path: polygon(20% 73%, 20% 73%, 80% 73%, 80% 73%, 100% 0, 100% 0, 0 0, 0 0);
    }
    .leftSlant,
    .rightSlant {
        width: 43%;
    }
    .backgroundHeader {
        font-size:22px;
    }
    .backgroundText {
        padding:0.8% 20%;
    }

    #midMountain.bubbleKnowledge {
        left:calc(50% - 220px); top:240px;
        transform:scale(0.6);
    }
    .midKnowledgeText:nth-child(4) { /* NodeJS */ transform:rotateZ(-14deg) translate(30%, 980%); }
    .midKnowledgeText:nth-child(7) { /* C/C++ */ transform:rotateZ(-7deg) translate(120%, 895%); }
    .midKnowledgeText:nth-child(10) { /* Elementor */ 
        transform:rotateZ(-9deg) translate(5%, 530%); 
        font-size:24px;
    }
    #midMountain.bubbleExperience {
        left:calc(50% - 250px); top:117px;
        transform-origin: center 57%;
        transform:scale(1);
    }
    #midMountain.bubbleBackground {
        left:calc(50% - 300px); top:390px;
        transform:scale(0.7);
    }

    #smallMountain.bubbleKnowledge {
        left:calc(50% - 260px); top:485px;
        transform:scale(0.7);
    }
    #smallMountain.bubbleExperience {
        left:calc(50% - 250px); top:153px;
        transform:scale(0.25);
    }
    #smallMountain.bubbleBackground {
        left:calc(50% - 210px); top:520px;
        transform:scale(0.55);
        padding-bottom:50px;
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(1) rotateZ(180deg);
    }

    @keyframes aboutHomeButton {
        from {
            opacity:0;
            right:100px;
        } to {
            opacity:1;
            right:0px;
        }
    }

    @keyframes switchScaleIn {
        0% {
            opacity:0;
            transform:scale(0.3);
        } 80% {
            opacity:0;
            transform:scale(0.3);
        } 90% {
            opacity:0.8;
            transform:scale(0.9);
        } 100% {
            opacity:1;
            transform:scale(0.65);
        }
    }
}

@media only screen and (max-width:320px) {
    .aboutButtonContainer {
        transform:scale(0.7);
        top:5px; right:-15px;
        z-index:2;
    }

    .aboutTextContainer {
        padding:0px 9% 150px 9%;
    }
    .aboutHeader {
        font-size:28px;
        padding:38% 0 15px 0;
        width:320px;
    }
    .bioLetter {
        font-size:14px;
        line-height:26px;
    }
    .aboutLogoDiv {
        height:60px;
    }
    .aboutLogoCircle {
        width:42px; height:42px;
    }
    .aboutLogo {
        width:20px; height:20px;
    }
    .aboutLogoText {
        left:60px;
        font-size:14px;
        letter-spacing:1px;
        width:220px;
        white-space:normal;
    }

    .mountainText {
        font-size:21px;
        transform:translate(-50%, 220px);
    }
    .mountainSwitch {
        margin-top:650px;
        transform:scale(0.6);
    }
    @media (hover: hover) {
        .mountainSwitch:hover {
            transform:scale(0.8);
        }
    }
    .mountainContainer.switch .mountainSwitch {
        margin-left:-70px;
        bottom:calc(100% - 200px);
        transform: scale(0.6);
    }
    .mountainContainer.bubble .mountainSwitch {
        top:-54px;
        left:-26%;
        transform:scale(0.55);
    }

    #bigMountain.bubbleKnowledge {
        bottom:40%;
    }
    #bigMountain.bubbleExperience {
        left:calc(50% - 250px); top:138px;
        transform:scale(0.88);
    }
    #bigMountain.bubbleBackground {
        left:calc(50% - 250px); top:95px;
        clip-path: polygon(20% 80%, 20% 80%, 80% 80%, 80% 80%, 100% 0, 100% 0, 0 0, 0 0);
    }
    .leftSlant,
    .rightSlant {
        display:none;
    }
    .backgroundHeader {
        font-size:19px;
    }
    .backgroundText {
        padding:0.8% 24%;
    }

    #midMountain.bubbleKnowledge {
        bottom:7%;
    }
    #midMountain.bubbleExperience {
        left:calc(50% - 250px); top:67px;
        transform:scale(0.88);
    }
    #midMountain.bubbleBackground {
        left:calc(50% - 295px); top:365px;
        transform:scale(0.55);
    }

    #smallMountain.bubbleKnowledge {
        bottom:-26%;
    }
    #smallMountain.bubbleExperience {
        left:calc(50% - 250px); top:103px;
        transform:scale(0.21);
    }
    #smallMountain.bubbleBackground {
        left:calc(50% - 210px); top:445px;
        transform:scale(0.4);
    }

    #bigMountain.rotate, #midMountain.rotate {
        transform:scale(0.88) rotateZ(180deg);
    }

    @keyframes aboutHomeButton {
        from {
            opacity:0;
            right:90px;
        } to {
            opacity:1;
            right:-15px;
        }
    }

    @keyframes switchScaleIn {
        0% {
            opacity:0;
            transform:scale(0.25);
        } 80% {
            opacity:0;
            transform:scale(0.25);
        } 90% {
            opacity:0.8;
            transform:scale(0.8);
        } 100% {
            opacity:1;
            transform:scale(0.6);
        }
    }
}