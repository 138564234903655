.App { overflow-y:auto !important }

.projectButtonContainer {
    position:absolute;
    width:190px;
    height:80px;
    top:50px; right:-55px;
    transform:scale(1.2);
    transition:0.3s;
    z-index:1;
    cursor:pointer;
    animation:projectHomeButton 1.5s ease;
}
.projectButtonCircle {
    position:absolute;
    top:50%; right:50%;
    transform:translate(50%, -50%);
    margin-right:125px;
    width:70px; height:70px;
    border-radius:100%;
    background:radial-gradient(rgba(241, 252, 255, 0.322), rgba(12, 186, 255, 0));
    filter:drop-shadow(0 0 0px rgba(255, 255, 255, 0));
    transition:0.5s;
}
@media (hover: hover) {
    .projectButtonContainer:hover .projectButtonCircle {
        filter:drop-shadow(0 0 20px rgb(255, 255, 255));
    }
}
.projectButtonArrow {
    position:absolute;
    top:50%; left:50%;
    transform:translate(-50%, -50%) rotateZ(45deg);
    width:20px; height:20px;
    border-bottom:2px solid white;
    border-left:2px solid white;
    margin-left:4px;
    opacity: 0.6;
    filter:drop-shadow(0 0 2px white);
}
.projectButtonText {
    position:absolute;
    top:50%; left:0;
    transform:translate(0%, -50%);
    margin-left:30px;
    font-size:20px;
    font-family: 'Nunito', sans-serif;
    font-weight:400;
    color:white;
    text-shadow:0 0 4px rgb(179, 179, 179);
    opacity:0.85;
    transition:0.5s;
}
.projectButtonText:after {
    content:"";
    position:absolute;
    display:block;
    right:-2px;
    text-align:right;
    width:58px; height:1px;
    background:white;
    margin-top:4px;
    margin-left:-3px;
    transition:0.5s;
    box-shadow:0 0 5px white;
    transform:scaleX(0);
    transform-origin:right;
}
@media (hover: hover) {
    .projectButtonContainer:hover .projectButtonText:after {
        transform:scaleX(1);
    }
}

.animateBody {
    animation:fadeInProject 2.5s ease-out;
}
.projectPageHeader {
    position:relative;
    font-size:60px;
    font-family: 'Fredoka One', cursive;
    font-weight:400;
    padding:180px 50px 40px 50px;
    color:white;
}
.projectSubheader {
    position:relative;
    padding:0 50px 0 50px;
    width:100%;
    max-width:1500px;
    font-size:26px;
    line-height:40px;
    font-family: 'Nunito', sans-serif;
    font-weight:400;
    color:white;
}
.projectList {
    position:relative;
    padding:50px 50px 0 50px;
    margin-bottom:100px;
    list-style-type:none;
    width:100%;
}
.projectLink {
    text-decoration:none;
}
.projectItem {
    margin-bottom:60px;
    transition:0.3s;
    cursor:pointer;
}
.projectItem:hover {
    transform:scale(1.01);
}
.projectHeader, .projectDescription {
    position:relative;
    width:100%;
    max-width:1800px;
    font-size:32px;
    line-height:40px;
    font-family: 'Nunito', sans-serif;
    font-weight:700;
    color:white;
    letter-spacing:1px;
}
.projectDescription {
    font-size:22px;
    font-weight:400;
    margin-top:10px;
    padding:0;
}


.sand {
    position:absolute;
    width:100%; height:350px;
    object-fit:cover;
    bottom:0;
    opacity:0.3;
}

@keyframes projectHomeButton {
    0% {
        opacity:0;
        right:85px;
    } 50% {
        opacity:0;
        right:85px;
    } 100% {
        opacity:1;
        right:-55px;
    }
}
@keyframes fadeInProject {
    from {
        opacity:0;
    } to {
        opacity:1;
    }
}

@media only screen and (max-width:700px) {
    .projectButtonContainer {
        transform:scale(1.05);
    }
    
    .projectPageHeader {
        font-size:50px;
    }
    .projectSubheader {
        font-size:22px;
    }
    .projectHeader, .projectDescription {
        font-size:28px;
    }
    .projectDescription {
        font-size:20px;
    }
}

@media only screen and (max-width:500px) {
    .projectButtonContainer {
        transform:scale(0.9);
    }
    
    .projectPageHeader {
        font-size:40px;
        padding:180px 30px 40px 30px;
    }
    .projectSubheader {
        font-size:20px;
        padding:0 30px;
        line-height:34px;
    }
    .projectList {
        padding:50px 30px 0 30px;
    }
    .projectItem {
        margin-bottom:50px;
    }
    .projectHeader, .projectDescription {
        font-size:25px;
    }
    .projectDescription {
        font-size:16px;
        line-height:30px;
    }
}

@media only screen and (max-width:400px) {
    .projectButtonContainer {
        top:30px;
        transform:scale(0.8);
    }
    
    .projectPageHeader {
        font-size:30px;
        padding:140px 20px 30px 20px;
    }
    .projectSubheader {
        font-size:17px;
        padding:0 20px;
        line-height:32px;
    }
    .projectList {
        padding:40px 20px 0 20px;
    }
    .projectItem {
        margin-bottom:50px;
    }
    .projectHeader, .projectDescription {
        font-size:23px;
    }
    .projectDescription {
        font-size:15px;
        line-height:30px;
    }
}